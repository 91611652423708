import { defineStore } from 'pinia';
import useAssets from '~~/composables/useAssets';
const assets = useAssets();

export const useMainStore = defineStore('main', {
    state: () => ({
      activeRoute: 'index',
      appStep: 0,
      isMobile: false,
      isNarrow: false,
      isTablet: false,
      menu: [
        {
          name: 'index',
          route: '/',
          translation: 'index',
          type: 'to-index',
          rel: 'next',
          icon: 'CustomIconHome'
        },
        // {
        //   name: 'about',
        //   route: '/about',
        //   translation: 'about',
        //   type: 'normal-link',
        //   rel: 'next'
        // },
        {
          name: 'contact',
          route: '/contact',
          translation: 'contact',
          type: 'button',
          rel: 'next',
          icon: 'CustomIconEnvelope'
        },
      ] as MenuItem[],
      // client References TBD
      clientReferences: [
        {
          name: "Axa",
          logo: assets.logo_axa,
          // translation of alt for SEO
          alt: {
              fr: "Logo Axa",
              en: "Axa Logo"
          },
          label: "Axa"
        },
        {
            name: "Botanic",
            logo: assets.logo_botanic,
            alt: {
                fr: "Logo Botanic",
                en: "Botanic Logo"
            },
            label: "Botanic"
        },
        {
            name: "Fnac Darty",
            logo: assets.logo_fnac_darty,
            alt: {
                fr: "Logo Fnac Darty",
                en: "Fnac Darty Logo"
            },
            label: "Fnac Darty"
        },
        {
            name: "Ford",
            logo: assets.logo_ford,
            alt: {
                fr: "Logo Ford",
                en: "Ford Logo"
            },
            label: "Ford"
        },
        {
            name: "Acadomia",
            logo: assets.logo_acadomia,
            alt: {
                fr: "Logo Acadomia",
                en: "Acadomia Logo"
            },
            label: "Acadomia"
        },
        {
            name: "BMW",
            logo: assets.logo_bmw,
            alt: {
                fr: "Logo BMW",
                en: "BMW Logo"
            },
            label: "BMW"
        },
        {
            name: "But",
            logo: assets.logo_but,
            alt: {
                fr: "Logo But",
                en: "But Logo"
            },
            label: "But"
        },
        {
            name: "Agence Nationale de l'Habitat",
            logo: assets.logo_anah,
            alt: {
                fr: "Logo ANAH",
                en: "ANAH Logo"
            },
            label: "Agence Nationale de l'Habitat"
        },
        {
            name: "BNP Paribas",
            logo: assets.logo_bnp_paribas,
            alt: {
                fr: "Logo BNP Paribas",
                en: "BNP Paribas Logo"
            },
            label: "BNP Paribas"
        },
        {
            name: "Croquetteland",
            logo: assets.logo_croquetteland,
            alt: {
                fr: "Logo Croquetteland",
                en: "Croquetteland Logo"
            },
            label: "Croquetteland"
        },
        {
            name: "Galeries Lafayette",
            logo: assets.logo_galeries_lafayette,
            alt: {
                fr: "Logo Galeries Lafayette",
                en: "Galeries Lafayette Logo"
            },
            label: "Galeries Lafayette"
        },
        {
            name: "Iberdrola",
            logo: assets.logo_iberdrola,
            alt: {
                fr: "Logo Iberdrola",
                en: "Iberdrola Logo"
            },
            label: "Iberdrola"
        },
        {
            name: "La Poste",
            logo: assets.logo_la_poste,
            alt: {
                fr: "Logo La Poste",
                en: "La Poste Logo"
            },
            label: "La Poste"
        },
        {
            name: "Mobilize Financial Services",
            logo: assets.logo_mobilize,
            alt: {
                fr: "Logo Mobilize Financial Services",
                en: "Mobilize Financial Services Logo"
            },
            label: "Mobilize Financial Services"
        },
        {
            name: "Nespresso",
            logo: assets.logo_nespresso,
            alt: {
                fr: "Logo Nespresso",
                en: "Nespresso Logo"
            },
            label: "Nespresso"
        },
        {
            name: "Nissan",
            logo: assets.logo_nissan,
            alt: {
                fr: "Logo Nissan",
                en: "Nissan Logo"
            },
            label: "Nissan"
        },
        {
            name: "SeLoger",
            logo: assets.logo_se_loger,
            alt: {
                fr: "Logo SeLoger",
                en: "SeLoger Logo"
            },
            label: "SeLoger"
        },
        {
            name: "Shiva",
            logo: assets.logo_shiva,
            alt: {
                fr: "Logo Shiva",
                en: "Shiva Logo"
            },
            label: "Shiva"
        },
        {
            name: "Yves Rocher",
            logo: assets.logo_yves_rocher,
            alt: {
                fr: "Logo Yves Rocher",
                en: "Yves Rocher Logo"
            },
            label: "Yves Rocher"
        },
        {
            name: "Zôdio",
            logo: assets.logo_zodio,
            alt: {
                fr: "Logo Zôdio",
                en: "Zôdio Logo"
            },
            label: "Zôdio"
        },
        {
            name: "EDF",
            logo: assets.logo_edf,
            alt: {
                fr: "Logo EDF",
                en: "EDF Logo"
            },
            label: "EDF"
        },
        {
            name: "NEXI",
            logo: assets.logo_nexi,
            alt: {
                fr: "Logo NEXI",
                en: "NEXI Logo"
            },
            label: "NEXI"
        },
        {
            name: "LVMH",
            logo: assets.logo_lvmh,
            alt: {
                fr: "Logo LVMH",
                en: "LVMH Logo"
            },
            label: "LVMH"
        },
        {
            name: "Align",
            logo: assets.logo_align,
            alt: {
                fr: "Logo Align",
                en: "Align Logo"
            },
            label: "Align"
        },
        {
            name: "Arcelor Mittal",
            logo: assets.logo_arcelor_mittal,
            alt: {
                fr: "Logo Arcelor Mittal",
                en: "Arcelor Mittal Logo"
            },
            label: "Arcelor Mittal"
        },
        {
            name: "Nouvelles Galeries",
            logo: assets.logo_nouvelles_galeries,
            alt: {
                fr: "Logo Nouvelles Galeries",
                en: "Nouvelles Galeries Logo"
            },
            label: "Nouvelles Galeries"
        },
        {
            name: "Banque Delubac & Compagnie",
            logo: assets.logo_delubac,
            alt: {
                fr: "Logo Banque Delubac & Compagnie",
                en: "banque Delubac & Compagnie Logo"
            },
            label: "Banque Delubac & Compagnie"
        },
        {
            name: "Consumer Science & Analytics",
            logo: assets.logo_csa,
            alt: {
                fr: "Logo Consumer Science & Analytics",
                en: "Consumer Science & Analytics Logo"
            },
            label: "Consumer Science & Analytics"
        },
      ],
    }),
    actions: {
      saveRoute(route:string) {
        this.activeRoute = route;
      },
    },
    getters: {},
  });